
import { defineComponent, reactive, toRefs } from 'vue';
import { handleClipboard, clipboardSuccess } from '@/utils/clipboard'; // use clipboard directly

export default defineComponent({
  setup() {
    const dataMap = reactive({
      activeName: 'directly',
      inputData: 'https://github.com/Armour/vue-typescript-admin-template',
      clipboardSuccess: clipboardSuccess,
      handleClipboard: handleClipboard
    });

    return { ...toRefs(dataMap) };
  }
});
